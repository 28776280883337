var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"row"},[_c('CustomFileInput',{attrs:{"className":'col-12',"defaultImg":_vm.fullPathFileFromServer(
            _vm.knownMethod.knownMethodImagePath,
            _vm.knownMethod.defaultImg
          ),"deleteFileStatus":_vm.deleteFileStatus &&
          !_vm.knownMethod.knownMethodImageIsDefault &&
          _vm.checkPrivilege(_vm.hasKnownMethodDeleteImage())},on:{"changeValue":function($event){_vm.knownMethod.mediaFile = $event.file},"deleteFile":function($event){return _vm.$emit('deleteFile')}}})],1),_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-fullCode"),"value":_vm.knownMethod.fullCode,"title":_vm.$t('KnownMethods.code'),"imgName":'code.svg'},on:{"changeValue":function($event){_vm.knownMethod.fullCode = $event}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-knownMethodNameAr"),"errors":_vm.errors_knownMethodNameAr,"value":_vm.knownMethod.knownMethodNameAr,"title":_vm.$t('KnownMethods.nameAr'),"imgName":'knownMethods.svg'},on:{"changeValue":function($event){_vm.knownMethod.knownMethodNameAr = $event;
            _vm.$v.knownMethod.knownMethodNameAr.$touch();}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-knownMethodNameEn"),"errors":_vm.errors_knownMethodNameEn,"value":_vm.knownMethod.knownMethodNameEn,"title":_vm.$t('KnownMethods.nameEn'),"imgName":'knownMethods.svg'},on:{"changeValue":function($event){_vm.knownMethod.knownMethodNameEn = $event;
            _vm.$v.knownMethod.knownMethodNameEn.$touch();}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-knownMethodNameUnd"),"value":_vm.knownMethod.knownMethodNameUnd,"title":_vm.$t('KnownMethods.nameUnd'),"imgName":'knownMethods.svg'},on:{"changeValue":function($event){_vm.knownMethod.knownMethodNameUnd = $event}}}),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-knownMethodDescriptionAr"),"errors":_vm.errors_knownMethodDescriptionAr,"value":_vm.knownMethod.knownMethodDescriptionAr,"title":_vm.$t('KnownMethods.descriptionAr'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.knownMethod.knownMethodDescriptionAr = $event;
            _vm.$v.knownMethod.knownMethodDescriptionAr.$touch();}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-knownMethodDescriptionEn"),"errors":_vm.errors_knownMethodDescriptionEn,"value":_vm.knownMethod.knownMethodDescriptionEn,"title":_vm.$t('KnownMethods.descriptionEn'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.knownMethod.knownMethodDescriptionEn = $event;
            _vm.$v.knownMethod.knownMethodDescriptionEn.$touch();}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-knownMethodDescriptionUnd"),"value":_vm.knownMethod.knownMethodDescriptionUnd,"title":_vm.$t('KnownMethods.descriptionUnd'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.knownMethod.knownMethodDescriptionUnd = $event}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-knownMethodNotes"),"value":_vm.knownMethod.knownMethodNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.knownMethod.knownMethodNotes = $event}}})],1)]),_c('div',{staticClass:"form-actions"},[_c('div',{staticClass:"icon-submit",on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/images/check-icon.svg"),"title":_vm.submitName}})]),_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(("ConfirmCloseSheet-" + _vm.bottomSheetName)),expression:"`ConfirmCloseSheet-${bottomSheetName}`"}],staticClass:"icon-cancel",on:{"click":function($event){$event.preventDefault();}}},[_c('img',{attrs:{"src":require("@/assets/images/cancel-icon.svg"),"title":_vm.$t('cancel')}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }